import Model, { attr, belongsTo } from '@ember-data/model';

export default class AppointmentModel extends Model {
  @attr('string', { defaultValue: '' })
  note;

  @attr('string', { defaultValue: '' })
  problemtext;

  @attr('string', { defaultValue: '' })
  background;

  @attr('string', { defaultValue: '' })
  background_1;

  @attr('string', { defaultValue: '' })
  background_2;

  @attr('string', { defaultValue: '' })
  background_3;

  @attr('boolean', { defaultValue: false })
  confirmed;

  @attr('boolean', { defaultValue: true })
  outside;

  @attr('number', { defaultValue: 0 })
  bookedId;

  @attr('number', { defaultValue: 0 })
  draftId;

  @attr('date')
  time;

  @attr
  bookingdate;

  @attr('number')
  amount;

  @attr
  committed;

  @attr
  finished;

  @attr
  done;

  @attr('boolean', { defaultValue: false })
  isroombooking;

  @attr('number', { defaultValue: 0 })
  bookingdays;

  @attr('boolean', { defaultValue: false })
  paid;

  @attr
  created;

  @attr
  updated;

  @belongsTo('outlet', {
    inverse: null,
    async: false,
  })
  outlet;

  @belongsTo('patient', {
    inverse: null,
    async: false,
  })
  patient;

  @belongsTo('appointmentstate', {
    inverse: null,
    async: false,
  })
  appointmentstate;

  @belongsTo('action', {
    inverse: null,
    async: false,
  })
  action;

  @belongsTo('room', {
    inverse: null,
    async: false,
  })
  room;

  @belongsTo('problem', {
    inverse: null,
    async: false,
  })
  problem;

  @belongsTo('treatment', {
    inverse: null,
    async: false,
  })
  treatment;

  @belongsTo('behandlingsstatus', {
    inverse: null,
    async: false,
  })
  behandlingsstatus;
}
