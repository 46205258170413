/* import __COLOCATED_TEMPLATE__ from './inline-form-patient.hbs'; */
import Component from '@glimmer/component';
import * as countries from 'i18n-iso-countries';
import * as danish from 'i18n-iso-countries/langs/da.json';
import * as countryTelData from 'country-telephone-data';
import ENV from 'boel/config/environment';
import { action } from '@ember/object';
import { inject as service } from '@ember/service';
import { tracked } from '@glimmer/tracking';
import error from 'boel/utils/error';

import fetch from 'fetch';
import {
  isServerErrorResponse,
  isUnauthorizedResponse,
  isAbortError,
} from 'ember-fetch/errors';

export default class AppInlineFormPatientComponent extends Component {
  @tracked Countries;
  @tracked Languages;

  @service store;
  @service currentUser;
  @service router;
  @service patienter;
  @service notifications;
  @service intl;
  @service session;

  @tracked Valid_Patient;
  @tracked status;
  @tracked states;
  @tracked patient;
  @tracked outletId;
  @tracked found_patient;
  @tracked kan_tilknyttes;
  @tracked sdmedlem;
  @tracked sother;
  @tracked Show_CPR_Error;
  @tracked Show_Confirm;
  @tracked Open = true;
  @tracked view_insurance = false;
  @tracked can_be_deleted = true;

  @action chooseOutlet(el, outlet) {
    this.chosenOutlet = outlet;
    el.setValue(outlet);
  }
  @action
  before_unload(event) {
    if (
      this.patient &&
      this.patient.changedAttributes() &&
      Object.keys(this.patient.changedAttributes()).length > 0
    ) {
      // Cancel the event as stated by the standard.
      event.preventDefault();
      // Chrome requires returnValue to be set.
      event.returnValue = '';
    }
  }
  @action
  will_destroy() {
    if (this.before_unload) {
      window.removeEventListener('beforeunload', this.before_unload);
    }
    if (this.patient) {
      if (this.patient.isNew || this.patient.hasDirtyAttributes) {
        if (this.patient.lastname && !this.Show_CPR_Error) {
          console.log("will_destroy",this.Show_CPR_Error); 
          this.save(this.patient);
          let outlet = this.patient.get('outlet');
          let outlet_id;
          if (outlet) {
            outlet_id = outlet.id;
          }
          if (
            outlet_id &&
            !this.currentUser.Is_CurrentActiveOutlet(outlet_id)
          ) {
            this.currentUser.ChangeOutlet(outlet_id);
          }
        }
      }
    } else {
      if (this.view_insurance && this.args && !this.args.isNew) {
        this.args.view_insurance(this.args.patient, null, true);
      }
    }
  }
  get CPR_Should_Be_Defined() {
    return this.args.isNew && (this.sdmedlem || this.sother);
  }
  @action
  delete_patient() {
    if (this.can_be_deleted) {
      if (this.session.isAuthenticated) {
        const headers = {
          'Content-Type': 'application/json',
        };
        headers['Authorization'] =
          `Bearer ${this.session.data.authenticated.access_token}`;

        let link =
          ENV.APP.namespaceRoot +
          '/' +
          ENV.APP.namespace +
          '/deletepatient?patientid=' +
          this.args.patient.id;

        fetch(link, {
          method: 'GET',
          cache: 'no-cache',
          headers: headers,
        })
          .then((response) => {
            if (response.ok) {
              if (ENV.APP.ShowNotifications) {
                this.notifications.success(
                  this.intl.t('form_patient.patient_deleted'),
                );
              }
              this.patient = null;
              this.args.patientdeleted();
            } else if (isUnauthorizedResponse(response)) {
              // handle 401 response
              this.notifications.error(
                this.intl.t('form_patient.session_timeout'),
              );
              this.session.invalidate();
            } else if (isServerErrorResponse(response)) {
              // handle 5xx respones
              this.notifications.error(
                this.intl.t('form_patient.server_error'),
              );
            }
          })
          .catch((error) => {
            if (isAbortError(error)) {
              // handle aborted network error
              this.notifications.error(
                this.intl.t('form_patient.network_error'),
              );
            }
          });
      }
    }
  }
  @action
  hide() {
    if (confirm(this.intl.t('form_patient.warning'))) {
      this.Open = false;
    } else {
      this.Open = true;
      return false;
    }
  }
  @action
  async Set_Valid_Patient(patient) {
    this.Valid_Patient =
      this.Er_Validt_Navn(patient) && (await this.Har_Nummer(patient));
    console.log('this.Valid_Patient', this.Valid_Patient);
  }
  @action
  Er_Validt_Navn(patient) {
    return patient && patient.lastname && patient.lastname.length > 0;
  }
  @action
  async choose_cpr(el, e) {
    if (el && e) {
      el.setValue(e);
      console.log('el: ', el, e);
      let cpr_nummer_org = e;

      if (cpr_nummer_org) {
        let has_seperator = cpr_nummer_org.indexOf('-') > -1;
        let correct_length =
          (has_seperator && cpr_nummer_org.length > 10) ||
          cpr_nummer_org.length > 9;
        let cpr_nummer = cpr_nummer_org.replaceAll('-', '');
        let isnum = /^\d+$/.test(cpr_nummer);

        if (correct_length && isnum) {
          this.found_patient = await this.patienter.cpr_search(cpr_nummer_org);
          if (!this.found_patient) {
            this.found_patient = await this.patienter.cpr_search(cpr_nummer);
          }
          this.Show_CPR_Error = this.found_patient != null;
          if (this.Show_CPR_Error) {
            this.Valid_Patient = false;
          } else {
            this.Set_Valid_Patient(this.patient);
          }
          return false;
        }
      }
      this.Show_CPR_Error = false;
      this.Set_Valid_Patient(this.patient);
    }
  }
  @action
  call_view_insurance() {
    this.view_insurance = true;
    this.tilbage();
  }
  @action
  async Har_Nummer(patient) {
    const cellphone = patient.cellphone,
      cpr_nummer = patient.cpr,
      phone = patient.telephone;
    let Validt_CPR_Nummer = false,
      Validt_Mobil_Nummer = false;
    let found_patient = null;
    if (cpr_nummer && cpr_nummer.length >= 10) {
      found_patient = await this.patienter.cpr_search(cpr_nummer);
      if (found_patient) {
        Validt_CPR_Nummer = found_patient.id == patient.id;
      } else {
        Validt_CPR_Nummer = true;
      }
    }
    if (cellphone && cellphone.length >= 8) {
      found_patient = await this.patienter.cellphone_search(cellphone);
      /*  if (found_patient)
      {
        Validt_Mobil_Nummer = found_patient.id == patient.id;
      }
      else {*/
      Validt_Mobil_Nummer = true;
      //  }
    } else if (phone && phone.length >= 8) {
      Validt_Mobil_Nummer = true;
    }

    return Validt_CPR_Nummer || Validt_Mobil_Nummer;
  }
  @action
  async Er_Mobil_Nummer_Fri(patient) {
    let found_patient = null;
    const cellphone = patient.cellphone;
    if (cellphone && cellphone.length >= 8) {
      // Findes patient allerede?
      found_patient = await this.patienter.cellphone_search(cellphone);
    }
    if (!found_patient) {
      return true;
    } else {
      // Nummer findes allerede

      if (this.args.isNew) {
        return false;
      } else {
        if (found_patient.id === patient.id) {
          // Patient med samme nummer er den som redigeres
          return true;
        } else {
          // Patient med samme nummer er den en anden patient end den som redigeres
          return false;
        }
      }
    }
  }
  @action
  async Er_CPR_Nummer_Fri(patient) {
    let found_patient = null;
    const cpr_nummer = patient.cpr;
    if (cpr_nummer && cpr_nummer.length === 11) {
      // Findes patient allerede?
      found_patient = await this.patienter.cpr_search(cpr_nummer);
    }
    if (!found_patient) {
      return true;
    } else {
      // Nummer findes allerede

      if (this.args.isNew) {
        return false;
      } else {
        if (found_patient.id === patient.id) {
          // Patient med samme nummer er den som redigeres
          return true;
        } else {
          // Patient med samme nummer er den en anden patient end den som redigeres
          return false;
        }
      }
    }
  }
  @action
  async Er_Nummer_Fri(patient) {
    let found_patient = null;

    const cpr_nummer = patient.cpr;
    const cellphone = patient.cellphone;

    if (
      (cpr_nummer && cpr_nummer.length === 11) ||
      (cellphone && cellphone.length >= 8)
    ) {
      // Findes patient allerede?
      found_patient = await this.patienter.cpr_search(cpr_nummer);

      if (!found_patient) {
        // CPR nummer findes ikke

        if (cellphone && cellphone.length >= 8) {
          found_patient = await this.patienter.cellphone_search(cellphone);
        }
      }

      if (!found_patient) {
        return true;
      } else {
        // Nummer findes allerede

        if (this.args.isNew) {
          return false;
        } else {
          if (found_patient.id === patient.id) {
            // Patient med samme nummer er den som redigeres
            return true;
          } else {
            // Patient med samme nummer er den en anden patient end den som redigeres
            return false;
          }
        }
      }
    } else {
      return true;
    }
  }
  @action
  async Er_Valid(patient) {
    let found_patient = null;
    let not_found = false;
    let cpr_nummer = patient.cpr;
    let cellphone = patient.cellphone;

    // Er forsikring valgt?
    if (this.CPR_Should_Be_Defined) {
      //Er CPR nummer udfyldt
      if (cpr_nummer && cpr_nummer.length === 11) {
        // Findes patient allerede?
        found_patient = await this.patienter.cpr_search(cpr_nummer);
        this.Show_CPR_Error = false;
      } else {
        this.Show_CPR_Error = true;
        return false;
      }
    } else if (cellphone && cellphone.length > 7) {
      // Findes patient allerede?
      found_patient = await this.patienter.cellphone_search(cellphone);
    }
    not_found = found_patient == null;

    if (found_patient) {
      this.found_patient = found_patient;
      this.kan_tilknyttes = this.patienter.kan_tilknyttes(
        this.found_patient,
        this.currentUser,
      );
    }
    return not_found;
  }

  @action
  async save(patient) {
    try {
      patient.lastname = patient.lastname.trim();
      patient.middlename = patient.middlename.trim();
      patient.firstname = patient.firstname.trim();
      patient.address = patient.address.trim();
      patient.telephone = patient.telephone.trim();
      patient.cellphone = patient.cellphone.trim();
      patient.email = patient.email.trim();
      patient.cpr = patient.cpr.trim();
      patient.country = patient.country.trim();
      patient.language = patient.language.trim();
      patient.postalcode = patient.postalcode.trim();
      patient.city = patient.city.trim();

      patient = await patient.save();

      if (this.args.isNew) {
        this.args.choosepatient(patient);
        this.args.selectPatient(patient);
        if (ENV.APP.ShowNotifications) {
          this.notifications.success(
            this.intl.t('form_patient.create_patient_success'),
          );
        }
      } else {
        if (ENV.APP.ShowNotifications) {
          this.notifications.success(this.intl.t('form_patient.edit_success'));
        }
      }
      if (this.view_insurance) {
        this.args.view_insurance(patient, null, true);
      } else {
        this.args.selectPatient(patient);
      }
    } catch (err) {
      const Message = error.get_message(err);
      this.notifications.error(
        this.intl.t('error.generic', {
          message: Message,
        }),
      );
    }
  }
  @action
  async tilbage(target) {
    if (this.patient) {
      if (this.patient.lastname && !this.Show_CPR_Error) {
        await this.save(this.patient);
        if (target) {
          this.args.sectionview(target, this.patient);
        } else {
          this.args.cancel(target);
        }
      } else if (
        !this.patient.changedAttributes() ||
        Object.keys(this.patient.changedAttributes()).length == 0
      ) {
        if (target) {
          this.args.sectionview(target);
        } else {
          this.args.cancel(target);
        }
      } else if (
        this.patient.changedAttributes() &&
        Object.keys(this.patient.changedAttributes()).length == 1 &&
        this.patient.changedAttributes().lastname
      ) {
        if (target) {
          this.args.sectionview(target);
        } else {
          this.args.cancel(target);
        }
      }
    }
  }
  @action
  gem(model) {
    let invalid = this.Show_CPR_Error;
    if (!invalid) {
      if (this.patient && this.patient.lastname) {
        this.save(model);
        let outlet = this.patient.get('outlet');
        let outlet_id;
        if (outlet) {
          outlet_id = outlet.id;
        }
        if (outlet_id && !this.currentUser.Is_CurrentActiveOutlet(outlet_id)) {
          this.currentUser.ChangeOutlet(outlet_id);
        }
      }
    }
  }
  @action
  oncreate(a, b) {
    try {
      if (a) {
        a.setValue(b);
      }
    } catch (err) {}
  }
  @action
  async create_problem(search_term) {
    let problem = await this.store.createRecord('problem', {
      name: search_term,
    });
    await problem.save();
    this.args.patient.problem = problem;
  }
  @action
  customSuggestion(search_term) {
    return this.intl.t('form_payment.create_problem_suggestion', {
      search_term,
    });
  }
  @action
  Choose_Postal_Code(number, city) {
    this.patient.postalcode = number;
    this.patient.city = city;
  }

  @action
  update() {
    if (this.args.patient) {
      this.patient = this.args.patient;
    } else {
      if (!this.patient) {
        let user_outlet = this.currentUser.CurrentActiveOutlet;
        this.patient = this.store.createRecord('patient', {
          outlet: user_outlet,
        });
      }
    }
    this.Set_Valid_Patient(this.patient);
    this.can_be_deleted = !this.args.isNew;
    this.Show_CPR_Error = false;
    this.sother = this.patient.sother;
    this.sdmedlem = this.patient.sdmedlem;
    this.chosenOutlet = this.patient.outlet;

    if (this.before_unload) {
      window.removeEventListener('beforeunload', this.before_unload);
    }
    window.addEventListener('beforeunload', this.before_unload, false);
  }
  NewPhoneNumber(Old_Phone_Number, Prefix, Old_Dial_Code) {
    let New_Phone_Number = '';
    if (!Old_Phone_Number) {
      New_Phone_Number = '+' + Prefix + '-';
    } else {
      Old_Phone_Number = Old_Phone_Number.replaceAll('+', '');
      Old_Phone_Number = Old_Phone_Number.replaceAll('-', '');
      Old_Phone_Number = Old_Phone_Number.replaceAll(' ', '');
      Old_Phone_Number = Old_Phone_Number.trim();

      if (Old_Dial_Code) {
        const regex = new RegExp(`^${Old_Dial_Code}`, 'g');
        Old_Phone_Number = Old_Phone_Number.replace(regex, '');
      }
      New_Phone_Number = '+' + Prefix + '-' + Old_Phone_Number;
    }
    return New_Phone_Number;
  }
  @action
  chooseCountry(el, val) {
    let Code;
    let Old_Dial_Code = '';
    let New_Dial_Code = '';
    var Old_Country = this.patient.get('country');
    if (Old_Country) {
      Code = countries.getAlpha2Code(Old_Country, 'da').toLowerCase();
      countryTelData.allCountries.find((x) => {
        if (x.iso2 == Code) {
          Old_Dial_Code = x.dialCode;
        }
      });
    }
    if (val) {
      Code = countries.getAlpha2Code(val, 'da').toLowerCase();
      countryTelData.allCountries.find((x) => {
        if (x.iso2 == Code) {
          New_Dial_Code = x.dialCode;
        }
      });
    }
    if (New_Dial_Code) {
      let Old_Phone_Number = this.patient.cellphone;
      let New_Phone_Number = this.NewPhoneNumber(
        Old_Phone_Number,
        New_Dial_Code,
        Old_Dial_Code,
      );
      if (Old_Phone_Number != New_Phone_Number) {
        this.patient.cellphone = New_Phone_Number;
      }

      Old_Phone_Number = this.patient.telephone;
      New_Phone_Number = this.NewPhoneNumber(
        Old_Phone_Number,
        New_Dial_Code,
        Old_Dial_Code,
      );
      if (Old_Phone_Number != New_Phone_Number) {
        this.patient.telephone = New_Phone_Number;
      }
    }
    el.setValue(val);
  }
  @action
  didinsert() {
    this.update();
    window.setTimeout(() => {
      const backup = this.patient.note;
      this.patient.note = this.patient.note + '_';
      this.patient.note = backup;
    }, 500);
    this.states = this.store.findAll('appointmentstate');
    countries.registerLocale(danish);
    this.Countries = Object.values(countries.getNames('da')).sort();
    let languageNames = new Intl.DisplayNames(['da'], { type: 'language' });
    this.Languages = [];
    ENV.supported_languages.forEach((element) => {
      this.Languages.push(languageNames.of(element));
    });
  }
}
